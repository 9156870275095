$body_color: #17222B;
$heading_color: #17222B;
$secondary_color: #1091FF;
$dark_color: #29333C;
$input_color: #80868B;
$dark_social_color: #C7CACC;
$footer_dark_color: #747A80;
$contact_icon: #A7A7A7;
$contact_icon_text: #8B9095;
$news_letter_body: #39424A;
$meta_date_color: #50585F;
$post_text_color: #384149;
$share_number_color: #A9ADB1;
$nav_color: #C1C4C6;
$type8_counter_color: #CED0D2;
$see_all_color: #8A8F94;
$type10_meta_color: #71787D;
$author_text_color: #73797F;
$tab_deactive_color: #A0A5A8;
$tranding3_color: #1091FF;
$page_cm_color: #888E92;
$comment_form_border_color: #C4C7C9;

$v4meta_color: #FF5555;
$v4meta_color2: #FF9999;


/*DARK VERSION COLORS START*/

$dark_meta_color: #C4C7C9;
$dark_nav_color: #515A60;
$dark_post_text_color: #6A7177;
$dark_footer_submit_text_color: #6A7177;

/*DARK VERSION COLORS END*/

$app_btn_color: #4E575E;
$meta_color31: #737A7F;
$social_icon3_color: #70BDFF;
$meta_col_color: #CACCCE;
$view_author_color: #515960;

$primary_bg: #17222B;
$secondary_bg: #1091FF;
$third_bg: #232D36;
$fourth_bg: #F0F0F1;
$fifth_bg: #FCFCFC;
$white: #FFFFFF;
$border_black_color: #DCDEDF;
$border_solid_color: #B9BCBF;
$border_second_color: #3A434B;
$tranding_bg: #FF5555;
$dark_social_bg: #212C34;
$bg_white: #ffffff;
$input_bg: #E9EAEB;

$facebook_bg: #3B5998;
$facebook_bg2: #334D82;
$twitter_bg: #1DA1F2;
$twitter_bg2: #1B87CA;
$youtube_bg: #FF5050;
$youtube_bg2: #D04648;
$instagram_bg: #9B45D5;
$instagram_bg2: #803DB3;
$vimeo_bg: #1AB7EA;
$vimeo_bg2: #1999C3;
$medium_bg: #00AB6C;
$medium_bg2: #048F5E;
$trading_bg: #FF5555;
$pagination_bg: #E4E5E6;
$text_bg: #DADCDD;

$next_prev_border_bg: #BDC0C2;


/*DARK VERSION BACKGROUND START*/

$dark_footer_bg: #233340;
$dark_number_bg: #29333C;

/*DARK VERSION BACKGROUND END*/
$theme3_bg: #ECEDEE;

$input3_bg: #ECEDEE;
$trandin2_bg: #FFE5E5;
$show_btn_bg: #F2F9FF;
$more_news_border: #E7E8E9;
$news3bg: #F2F6F8;
$border4: #ECEDEE;
$bg4: #FAFAFA;
$bg4readmore_bg: #F8EDF9;
$category4_bg: #E8E8EA;
$newsletter4_bg: #4F585E;
$videoAuthor_span: #8A8F94;
$videoAuthori: #FE9898;
$dots_color: #827A6F;
$scroll_bar_color: #A9ACB0;
$tab4_color: #A2A7AA;


/*GRADIENT COLORS START*/

$inner_gradient_bg: linear-gradient(to left, transparent, #17222B);
$gradient_bg2: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
$gradient41: linear-gradient(90deg, #FF5555, #AB50EF);
$gradient42: linear-gradient(90deg, #FEEDEE, #F7EEFD);
$gradient43: linear-gradient(to top, #000, transparent);

/*GRADINET COLORS END*/


/*SHADOW START*/

$shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
$shadow2: 0 2px 16px rgba(0, 0, 0, 0.1);
$shadow3: 0 10px 40px rgba(0, 0, 0, 0.1);
$shadow4: 0 10px 40px rgba(0, 0, 0, 0.15);
$shadow5: 0 72px 20px 0 #fbfbff;
$shadow6: 0 2px 10px rgba(0, 0, 0, 0.1);

$shadow7: 0 3px 6px #f1f1f1;

/*SHADOW END*/

$readmore_border_color: #50585F;
$secondary_border_color: #1091FF;
$show_btn_border: #BADFFF;